import React from 'react'
import { capitalizeWords, dateTimeFormat } from '../../../utils/HelperFuncitons'

function RidesDetailModal({rideDetail,modalId}) {
  const rideStatus = (status, cancelledBy) => {
    return (
      status === "pending" ? "Requested" :
        status === "accepted" ? "On route to Pickup" :
          status === "arrived" ? "Arrived at Pickup Point" :
            status === "startRide" ? "In-progress" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled By ${capitalizeWords(cancelledBy)} ` : "-")
  }
  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          
          <div className="modal-body ">
            <div className=' d-flex justify-content-between'>
              <h2 >
                Ride Details
              </h2>
              <div className=' border-0'>

              <button type="button" className="btn-close block-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
            <table className='table'>
              <tbody>
                
                <tr>
                  <td>Id</td>
                  <td>{rideDetail?.id}</td>
                </tr>
                <tr>
                  <td>Customer</td>
                  <td>{`${rideDetail?.userDetails?.firstName ? capitalizeWords(rideDetail?.userDetails?.firstName) : ""} ${rideDetail?.userDetails?.lastName ? capitalizeWords(rideDetail?.userDetails?.lastName) : ""}`}</td>
                </tr>
                <tr>
                  <td>Driver</td>
                  <td>{rideDetail?.driverDetails?.name ? rideDetail?.driverDetails?.name : "-"}</td>
                </tr>
                <tr>
                  <td>From</td>
                  <td>{rideDetail?.pickup ? rideDetail?.pickup : "-"}</td>
                </tr>
                <tr>
                  <td>To</td>
                  <td>{rideDetail?.dropOff ? rideDetail?.dropOff : "-"}</td>
                </tr>
                
                <tr>
                  <td>Fare</td>
                  <td>{rideDetail?.charges ? rideDetail?.charges : "-"}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{rideDetail?.paymentMode ? rideDetail?.paymentMode : "-"}</td>
                </tr>
                <tr>
                  <td>Pickup Time</td>
                  <td>{rideDetail?.pickupTime ? dateTimeFormat(Number(rideDetail?.pickupTime)) : "-"}</td>
                </tr>
                <tr>
                  <td>DropOff Time</td>
                  <td>{rideDetail?.dropOffTime && rideDetail?.rideStatus === "completed" ? dateTimeFormat(Number(rideDetail?.dropOffTime)) : "-"}</td>
                </tr>
                <tr>
                  <td>Total Time</td>
                  <td>{rideDetail?.duration ? rideDetail?.duration : "-"}</td>
                </tr>
                <tr>
                  <td>Total KM</td>
                  <td>{rideDetail?.distance ? rideDetail?.distance : "-"}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{rideDetail?.rideStatus ? rideStatus(rideDetail.isCancelled ? "cancelled" : rideDetail?.rideStatus, rideDetail.cancelledBy) : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RidesDetailModal