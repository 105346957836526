import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useAdminContext } from '../../../context/adminContext';
import { foodOrdersListApi, getRidesListApi } from '../../../utils/ApiEndpointFunctions';
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import BreadCrumb from '../../common/BreadCrumb';
import LimitPagination from '../../common/LimitPagination';
import RidesDetailModal from '../../common/Modal/RidesDetailModal';
import CommonTable from '../../common/Table/CommonTable'

function FoodOrders() {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedRide, setSelectedRide] = useState()
  const rideDetailModalId = "rideDetailModal"
  // const limit = 10;
  const { limit } = useAdminContext();

  const rideStatus = (status, cancelledBy) => {

    return (
      status === "pending" ? "Requested" :
        status === "accepted" ? "On route to pickup" :
          status === "arrived" ? "Arrived at pickup point" :
            status === "startRide" ? "In-progress" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }


  const handleChange = async (event) => {

    if (event.target.name === "searchField") {
      let search1 = event.target.value;
      setSearch(search1);
      setLoader(true);
      const res = await foodOrdersListApi(limit, 1, search1);
      if (res.status === 200) {
        const usersData = res?.data?.rides
        const totalLength = res?.data?.totalLength;

        console.log(res.data)
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(usersData);
        setSelectedPage(1);
      }
    }
    setLoader(false);
  };

  const debouncedOnChange = debounce(handleChange, 800);

  const getAllrides = async (selectedPage, limit) => {

    try {
      setLoader(true)
      const res = await foodOrdersListApi(limit, selectedPage, search)
      if (res.status === 200 || res.status === 201) {
        console.log(res.data)
        const usersData = res?.data?.ordersList;
        const totalLength = res?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(usersData);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getAllrides(currentPage, limit)
    setSelectedPage(currentPage)

  };

  const headerData = [
    { name: "Id", className: "col-1", key: "id" },
    { name: "Customer", className: "col-1", key: "customer" },
    { name: "Driver", className: "col-1", key: "driver" },
    { name: "From", className: "col-1", key: "pickup" },
    { name: "To", className: "col-1", key: "dropoff" },
    { name: "Fare", className: "col-1", key: "charges" },
    { name: "Type", className: "col-1", key: "paymentMode" },
    { name: "Status", className: "col-1", key: "rideStatus" },
    { name: "Action", className: "col-1", key: "action" },

  ]

  const RideTableData = tableData?.map((item) => ({
    id: item.id,
    customer: `${item?.userDetails?.firstName ? capitalizeWords(item?.userDetails?.firstName) : ""} ${item?.userDetails?.lastName ? capitalizeWords(item?.userDetails?.lastName) : ""}`,
    driver: item?.driverDetails?.name || "-",
    pickup: (
      <span title={item?.pickup}>{item?.pickup ? item?.pickup?.slice(0, 10) + (item?.pickup?.length > 10 ? '...' : "") : "-"}</span>
    ),
    dropoff: (
      <span title={item?.dropOff}>{item?.dropOff ? item?.dropOff?.slice(0, 10) + (item?.dropOff?.length > 10 ? '...' : "") : "-"}</span>
    ),
    charges: item?.charges || "-",
    paymentMode: item?.paymentMode ? capitalizeWords(item?.paymentMode) : "-",
    rideStatus: item?.rideStatus ? rideStatus(item.isCancelled ? "cancelled" : item?.rideStatus, item.cancelledBy) : "-",
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item">
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${rideDetailModalId}`}
              style={{ cursor: "pointer" }}
              onClick={() => { setSelectedRide(item) }}
            >
              View
            </div>
          </li>
        </ul>
      </div>
    )
  }))


  useEffect(() => {
    getAllrides(selectedPage, limit);
  }, [])

  return (

    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <ul className="nav nav-tabs">
                    <li className="nav-item ms-3">
                      <button
                        className='nav-link active'
                      // className={`nav-link ${(activeTab === 'active' || activeTab == null) ? 'active' : ''}`}
                      >
                        All
                      </button>
                    </li>
                  </ul>
                  <div className="card">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0 py-4">
                      <LimitPagination getData={getAllrides} />
                      <div style={{ display: "-webkit-inline-box" }}>
                        {/* <div className="position-relative my-3">
                          <input
                            className="form-search me-3"
                            type="search"
                            name="searchField"
                            id=""
                            placeholder="Search by email"
                            onChange={debouncedOnChange}
                          />
                        </div> */}
                      </div>
                    </div>
                    <CommonTable headers={headerData} data={RideTableData} loading={loader} />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}

      <RidesDetailModal rideDetail={selectedRide} modalId={rideDetailModalId} />
    </>

  )
}

export default FoodOrders