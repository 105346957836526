import moment from 'moment'

export const  getToken = () => localStorage.getItem("token")

export const removeToken = () => localStorage.removeItem("token")
export const isAuth = () => Boolean(getToken())

export const capitalizeWords=(str)=> {
    if(!str) return
    const words = str?.split?.(' ');
  
    const capitalizedWords = words?.map(word => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      } else {
        return word; 
      }
    });
  
    return capitalizedWords?.join(' ');
  }

  export const openImageInNewTab = (image) => {
    const imageUrl = `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${image}`
    window.open(imageUrl, '_blank');
  };
  
// export const convertTo12HourFormat = (time24) => {
//   if(!time24) return "";
//   let [hours, minutes] = time24.split(':');
//   let period = hours >= 12 ? 'PM' : 'AM';
//   hours = hours % 12 || 12;
//   return `${hours}:${minutes} ${period}`;
// }

export const convertTo12HourFormat = (time24) => {
  if(!time24) return "";
  const moment = require('moment');
  return moment(time24, 'HH:mm').format('h:mm A');
}

export const convertByteToMB = (byte) => {
  if(!byte) return 0;
  return byte / (1024 * 1024)
}

export const convertTimeTo12HrFormat = (time) => {
  if(!time) return ;
  var timeParts = time.split(":");
  var hours = parseInt(timeParts[0]);
  var minutes = parseInt(timeParts[1]) || 0;
  var seconds = parseInt(timeParts[2]) || 0;

  var suffix = hours >= 12 ? "PM" : "AM";
  
  hours = hours % 12 || 12;

  // Add leading zero to minutes and seconds if they're less than 10
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  var formattedTime = hours + ":" + minutes + ":" + seconds + " " + suffix;

  return formattedTime;
}

export const dateFormat = (date) => {
  if(!date) return;
  return moment(date).format("LL")
}

export const dateTimeFormat = (date) => {
  if(!date) return;
  return moment(date).format("LLL")
}

