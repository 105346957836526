import React from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';

const Header = () => {
  // const [adminInfo,setAdminInfo] = useState();
  const data = useLocation();
  const allPaths = data.pathname.split('/');
  const token = localStorage.getItem("token")
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePartners = (e) => {
    let params = {type: e.target.value}
    if(e.target.value === "driver") params["tab"] = "driver"
    if(e.target.value === "package") params["tab"] = "category"
    if(e.target.value === "rentals") params["tab"] = "vendor"
    if(e.target.value === "restaurant") params["tab"] = "restaurant"
    setSearchParams(params)
  }
  
  return (
    <>{token && <>
      <div className="main-content  overflow-y-a">
        <div className="header">
          <div className="container-fluid">
            {/* Body */}
            <div className="header-body">
              <div className=" d-flex justify-content-between align-items-end">
                <div className="">
                  {/* Pretitle */}
                  <h6 className="header-pretitle">Overview</h6>
                  {/* Title */}
                  <h1 className="header-title">{allPaths[1][0]?.toUpperCase() +
                    allPaths[1]?.slice(1).split('-').join(" ")}</h1>
                </div>
                <div className="" style={{width:"15%"}}>
                  {allPaths?.join("") === "partners" && <select className="form-select w-100" aria-label="" value={searchParams.get("type")} onChange={handlePartners} >
                    <option value="all">All</option>
                    <option value="driver">Driver</option>
                    <option value="package">Package</option>
                    <option value="rentals">Hire/Rentals</option>
                    <option value="restaurant">Restaurant</option>
                  </select>}
                  {/* Button */}
                  {/* <div className="dropdown">
                   
                    <button
                      className="btn btn-second dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={`${
                          adminInfo?.profilePic
                            ? ImageUrl + "/" + adminInfo?.profilePic
                            : process.env.PUBLIC_URL +
                              "/images/person-circle.svg"
                        }`}
                        alt=""
                        height={24}
                        width={24}
                        className="rounded-circle me-2"
                      />
                      {adminInfo?.firstName+" "+adminInfo?.lastName}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={"/profiledetails"}>
                          Profile
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>{" "}
              {/* / .row */}
            </div>{" "}
            {/* / .header-body */}
          </div>
        </div>
      </div>
      </>}
    </>
  )
}

export default Header
