import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router-dom';
import { useAdminContext } from '../../../context/adminContext';
import { getUserRidesListApi } from '../../../utils/ApiEndpointFunctions';
import { S3_URL } from '../../../utils/BaseUrl'
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import LimitPagination from '../../common/LimitPagination';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import Spinwheel from '../../common/Spinwheel';

function CustomerDetails() {
  const params = useParams();
  const [tableData, setTableData] = useState([])
  const [userDetails,setUserDetails] = useState({})
  const [id, setId] = useState(params?.id)
  const [filter, setFilter] = useState("all")
  const [loader, setLoader] = useState(true)
  const [tabelLoader, setTableLoader] = useState(false)
  const [selectedImg, setSelectedImg] = useState("")
  const [page, setPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const { limit } = useAdminContext();
  const [totalCount, setTotalCount] = useState(null);

  const getUserRidesList = async (selectedPage, limit, filter1) => {
    try {
      setTableLoader(true)
      filter1 = filter1 || filter
      const res = await getUserRidesListApi(selectedPage, limit, filter1, id)
      if (res.status === 200) {
        console.log(res.data)
        const ridesData = res?.data?.data?.rideList;
        const totalLength = res?.data?.data?.totalCount;
        const userD = res?.data?.data?.userDetails
        setUserDetails(userD)
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(ridesData);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
    setTableLoader(false)
  }

  const handleFilter = (e) => {
    setFilter(e.target.value)
    getUserRidesList(selectedPage, limit, e.target.value)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getUserRidesList(currentPage, limit, filter)
    setSelectedPage(currentPage)
  };

  const rideStatus = (status, cancelledBy) => {
    return (
      status === "pending" ? "Requested" :
        status === "accepted" ? "On route to pickup" :
          status === "arrived" ? "Arrived at pickup point" :
            status === "startRide" ? "In-progress" :
              status === "completed" ? "Completed" :
                status === "cancelled" ? `Cancelled ${cancelledBy ? "By " + capitalizeWords(cancelledBy) : ""} ` : "-")
  }

  useEffect(() => {
    getUserRidesList(selectedPage, limit, filter);
  }, [])


  return (
    <div className="main-content">
      <div className="container-fluid">
        {loader ? (
          <div className="text-center">
            <Spinwheel />
          </div>
        ) : (
          <>
            <div className="row align-items-end">
              <div className="col-auto">
                <div className="avatar avatar-xxl header-avatar-top mt-3">
                  <img src={userDetails?.profileImage ? `${S3_URL}/${userDetails?.profileImage}` : "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                </div>
              </div>
              <div className="col mb-3 ms-n3 ms-md-n2">
                <h1 className="header-title">
                    {userDetails.firstName ? capitalizeWords(userDetails.firstName + ' ' + userDetails.lastName):"-"}
                </h1>
              </div>
            </div>

            <div className="row">
              <div className='d-flex justify-content-between'>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      className={`nav-link active`}
                    >
                      All
                    </button>
                  </li>
                </ul>
                <div>
                </div>
              </div>


              <div className="card">
                <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
                  <LimitPagination getData={() => { }} />
                  <div style={{ display: "-webkit-inline-box" }}>
                    <div className="position-relative my-3">
                      <select className="form-select-sm form-control-flush me-3 border " data-choices='{"searchEnabled": false}' value={filter} onChange={(e) => handleFilter(e)}>
                        <option value="all">All</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div className="table-responsive" style={{ overflowX: "inherit" }}>
                    <table className="table table-md table-hover card-table">
                      <thead>
                        <tr>
                          <th className='col-1'>
                            <span className="list-sort text-muted">Id</span>
                          </th>
                          <th className='col-1' >
                            <span className="list-sort text-muted">Driver</span>
                          </th>
                          <th className='col-1' >
                            <span className="list-sort text-muted">From</span>
                          </th>
                          <th className='col-1' >
                            <span className="list-sort text-muted">To</span>
                          </th>
                          <th className='col-1' >
                            <span className="list-sort text-muted">Fare</span>
                          </th>
                          <th className='col-1' >
                            <span className="list-sort text-muted">Type</span>
                          </th>
                          <th className='col-2' >
                            <span className="list-sort text-muted">Status</span>
                          </th>
                         
                        </tr>
                      </thead>
                      {tabelLoader ? (
                        <tbody>
                          <tr>
                            <td colSpan={12} className='text-center'>
                              <Spinwheel />
                            </td>
                          </tr>
                        </tbody>

                      ) : (
                        <tbody className="list fs-base">
                          {tableData?.length > 0 ? (
                            tableData?.map((ele, index) => {

                              return (
                                <tr key={index}>
                                  <td>{ele?.id}</td>
                                  <td>{ele?.driverDetails?.name ? ele?.driverDetails?.name : "-"}</td>
                                  <td title={ele?.pickup}>{ele?.pickup ? ele?.pickup?.slice(0, 10) + (ele?.pickup?.length > 10 ? '...' : "") : "-"}</td>
                                  <td title={ele?.dropOff}>{ele?.dropOff ? ele?.dropOff?.slice(0, 10) + (ele?.dropOff?.length > 10 ? '...' : "") : "-"}</td>
                                  <td>{ele?.charges ? ele?.charges : "-"}</td>
                                  <td>{ele?.paymentMode ? capitalizeWords(ele?.paymentMode) : "-"}</td>
                                  <td>{ele?.rideStatus ? rideStatus(ele.isCancelled ? "cancelled" : ele?.rideStatus, ele.cancelledBy) : "-"}</td>
                                  
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td align="center" colSpan="12">
                                <img
                                  src="/images/noData.png"
                                  style={{ width: "200px" }}
                                  alt=""
                                  width="50%"
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                </div>
              </div>
            </div>
          </>
        )}


        <ImageViewModal imgUrl={selectedImg} modalId={"imageViewModal"} />

        {totalCount > limit ? (
          <div className="d-flex justify-content-center">
            <ReactPaginate
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={page}
              previousLabel="< Previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName={"active"}
              forcePage={selectedPage-1}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default CustomerDetails